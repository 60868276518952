import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
    InputMasterType,
    ProviderSynchronizationResultDto,
    ProviderSynchronizationServiceProxy
} from "@shared/service-proxies/service-proxies";
import { groupBy, isNil } from "lodash-es";
import * as moment from "@node_modules/moment";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'app-synchronization-canvas',
    templateUrl: './synchronization-canvas.component.html',
    styleUrls: ['./synchronization-canvas.component.css']
})
export class SynchronizationCanvasComponent implements OnInit, OnChanges {
    @Input() inputMasterType: InputMasterType;
    @Input() operationDate: moment.Moment;

    expandedIndex: number;
    rows: any[] = [];
    loading: boolean;
    reloading: boolean;

    constructor(
        private readonly providerSynchronizationServiceProxy: ProviderSynchronizationServiceProxy,
        private readonly cdr: ChangeDetectorRef
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('operationDate' in changes && !changes.operationDate.firstChange) {
            this.rows = [];
            this.registerSynchronizationResults();
        }
    }

    ngOnInit(): void {
        if (isNil(this.inputMasterType)) {
            throw new Error("input master type is not defined");
        }

        this.registerSynchronizationResults();
    }

    onReloadClick() {
        this.reloading = true;
        this.providerSynchronizationServiceProxy.checkSynchronizedWithProvider(this.operationDate, this.inputMasterType)
            .pipe(finalize(() => {
                this.reloading = false;
                this.cdr.markForCheck();
            }))
            .subscribe(result => {
                this.rows = [...this.getDataGrouped(result), ...this.rows];
                this.cdr.detectChanges();
            });
    }

    private registerSynchronizationResults() {
        this.loading = true;
        this.providerSynchronizationServiceProxy.getSynchronizationResult(this.operationDate, this.inputMasterType)
            .pipe(finalize(() => {
                this.loading = false;
                this.cdr.markForCheck();
            }))
            .subscribe(results => {
                this.rows = [...this.getDataGrouped(results), ...this.rows];
                this.cdr.detectChanges();
            });
    }

    private getDataGrouped(items: ProviderSynchronizationResultDto[] = []) {
        const rows = [];
        const groups = groupBy(items, x => `${x.companyId}.${x.synchronizationBatchId}`);

        for (let groupsKey in groups) {
            const items = groups[groupsKey];
            const item = items[0];
            rows.push({
                companyName: item.companyName,
                creationTime: item.creationTime,
                providedByOnepact: items.every(x => x.providedByOnepact),
                providedByProvider: items.every(x => x.providedByProvider),
                isMatched: items.every(x => x.isMatched),
                children: items.sort((a, b) => a.companyName.localeCompare(b.companyName))
            })
        }

        return rows.sort((a, b) => b.creationTime.unix() - a.creationTime.unix());
    }

    onShowChildItemsClick(i: number) {
        this.expandedIndex = this.expandedIndex === i ? null : i;
    }

    showPowerPlantName() {
        return this.inputMasterType === InputMasterType.FinalDailyGeneration ||
            this.inputMasterType === InputMasterType.Availability ||
            this.inputMasterType === InputMasterType.HourlyBid;
    }

    showCounterPartyName() {
        return this.inputMasterType === InputMasterType.BilateralContracts;
    }

    showExpandPanel() {
        return this.inputMasterType !== InputMasterType.HourlyBid;
    }

    showProviderRecordStatus() {
        return this.inputMasterType == InputMasterType.BilateralContracts;
    }
}
